import styled from "styled-components";


export const ContactGroupContainer = styled.div`
  height: 550px;
  width: 400px;
  padding:  2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactContainer = styled.div`
  height: 550px;
  width: 400px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactBoxContainer = styled.div`
  width: calc(100% - 2rem);
  margin-bottom: 30px;
  margin-top: 30px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
`;

export const ContactHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  margin-bottom: 16px;
  overflow: auto;
`;

export const ContactPaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.lock ? 'position: absolute; bottom: 16px;' : ''}
  width: calc(100% - 40px);
  justify-content: center;
`;

export const ContactGroupHeader = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const ContactItem = styled.div`
  width: 100%;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 4px 4px 4px 8px;
  transition: 0.3s;
  &:hover {
    background-color: #EAEAEA;
  }
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: 0.5px;
  height: fit-content;
`;

export const ContactButtonDescription = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.15px;
  margin: 4px;
`;

export const InternalDialogContainerDescription = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  font-family: "Plus Jakarta Sans";
`;


export const MinorModalBoxContainer = styled.div`
  width: calc(100% - 2rem);
  margin-bottom: 30px;
  margin-top: 30px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MinorModalButtonContainer = styled.div`
  border-radius: 10px;
  text-align: center;
  width: 100px;
  font-size: 14px;
  line-height: 19px;
  font-family: "Plus Jakarta Sans";
  background-color: #0b1f82;
  cursor: pointer;
`;

export const ModalButtonContainer = styled.div`
  border-radius: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  background-color: #0b1f82;
  cursor: pointer;
`;


export const ModalButtonDescription = styled.p`
  font-size: 13.5px;
  line-height: 26px;
  color: white;
`;

export const Dropzone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed #777;
  color: #333;
  border-radius: 8px;
  font-size: 12px;
  letter-spacing: 0.25px;
`;

export const LoadingContainer = styled.div`
  height: 550px;
  width: 400px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;